<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">导出列表</span>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
                <le-input label="文件名称" v-model="pageParam.params.name" />
            </le-search-form>
            <le-pagview ref="exportPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.exportList">
                <el-table 
                    ref="exportList"
                    :data="tableData" 
                    :highlight-current-row="true" 
                    v-sticky="{ top: 0, parent:'.el-card__body' }"
                    style="width: 100%">
                    <el-table-column prop="createTimeText" label="导出时间" min-width="170">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTimeText?row.createTimeText:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fileName" label="文件名称" min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.fileName?row.fileName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startTime" label="时间范围" min-width="330">
                        <template slot-scope="{ row }">
                            <span>{{ row.startTime?row.startTime:'-' }} 至 {{ row.endTime?row.endTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" label="状态" min-width="100">
                        <template slot-scope="{ row }">
                            <span :class="'exportStatus'+row.state">{{ row.stateText?row.stateText:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="exportModelText" label="模块" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.exportModelText?row.exportModelText:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="60" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="下载" placement="top">
                                <img src="../../assets/icon/downlaod-icon.png" class="a-wh-16" @click="downLoadFile(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            pageParam: {
                url: this.$Config.apiUrl.exportFilePage,
                method: "post",
                params: {
                    name: '',
                },
                freshCtrl: 1,
            },
        };
    },
    filters:{
        initDic (value,dic) {
            if(value === '' || value === undefined || value === null){
                return ''
            }else{
                for(var i=0; i<dic.length;i++){
                    if(value==dic[i].value){
                        return dic[i].text
                    }
                }
            }
        },
    },
    mounted () {
        let _this = this
        document.onkeydown = function(event) {
            var e = event || window.event;
            if (e && e.keyCode == 13) { //回车键的键值为13
                _this.handlerSearch()
            }
        };

    },  
    activated () {
		this.pageParam.freshCtrl++;
	},
    methods: {
        setTableData(data,fileUrl) {
            this.tableData = data;
        },
        handlerRest() {
            this.pageParam.params = {
                name: ''
            };
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['exportPage'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        downLoadFile (datas) {
            if(datas.fileUrl){
                this.$Utils.download(datas.fileUrl, datas.fileName+'.'+datas.fileUrl.split('.')[datas.fileUrl.split('.').length - 1])
                // this.$Utils.downloadFile(datas.fileUrl, datas.fileName+'.'+datas.fileUrl.split('.')[datas.fileUrl.split('.').length - 1])
            }else{
                this.$message.warning('暂无可下载文件')
            }
        }
    },
}
</script>

<style lang="scss" scoped>
// 导出中
.exportStatus0{
    color: #409EFF;
}
// 导出成功
.exportStatus1{
    color: #67C23A;
}
// 导出失败
.exportStatus2{
    color: #F56C6C;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>
